#title-container {
    text-align: center;
    overflow: hidden;
}
#title-container h2 {
    text-transform: none;
    font-weight: normal;
    margin-top: 0;
    font-size: inherit;
}
#hero-container {
    margin: 15px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    text-align: center;
}
#hero-container .hero-content {
    overflow: hidden;
    cursor: pointer;
}#hero-container .hero-content.no-clickable {
     cursor: default;
 }
#hero-container .hero-content .hero-image {
    width: 100%;
    display: block;
    background-repeat:no-repeat;
    background-position: center;
    -webkit-background-size:cover;
    background-size:cover;
    transition-duration: .5s;
}

#hero-container .hero-content .hero-image img {
    width: 100%;
    aspect-ratio: auto 3.5;
}

@media (max-width: 767px) {
    #hero-container .hero-content .hero-image.hidden-xs {
        background-image: none!important;
    }

    /*#hero-container .hero-content .hero-image {
        height: 350px;
    }*/
}
@media (min-width: 768px) {
    #hero-container .hero-content .hero-image.visible-xs {
        background-image: none!important;
    }
    /*#hero-container .hero-content .hero-image{
        background-size: 100% 140%
    }*/
}
#hero-container .hero-content .hero-text {
    color: #000;
    /*margin: 20px 0;*/
    padding: 20px 1px 30px;
}
#hero-container .hero-content .hero-text .hero-text-title{
    color: #000;
    font-weight:bold;
    font-size:17px;
    margin-right:10px;
    text-decoration: none;
}

.bannerContentTitle p {
    color:white;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 1;
    -moz-box-orient: vertical;
    -ms-line-clamp: 1;
    -ms-box-orient: vertical;
    -o-line-clamp: 1;
    -o-box-orient: vertical;
}

.collectionTitle{
    margin-bottom: 25px;
    text-align:center;
}

.contentCollection {
    position: relative;
}

.textCollection {
    display: table;
    width: 100%;
    height: 100%;
    padding:10px;
}

.containerCollection{
    margin-bottom: 25px;
    cursor: pointer;
}

.containerCollection:hover .imageCollectionImage>img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.containerCollection a, .containerCollection p {
    color: #ffffff;
}

.containerCollection a:hover {
    text-decoration: none;
}

.imageCollectionImage>img {
    width: 100%;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.importantContainerCollection p.nameStickersCollection {
    text-align: left;
    float:left;
    margin-top: 4px;
    margin-left: 7px;
}

.importantContainerCollection p.stickersAvailableCollection {
    text-align: right;
    margin-right: 7px;
    float:right;
    margin-top:4px;
}

.importantContainerCollection .divInfoCollection {
    height: 35px;
}

.notImportantContainerCollection .divInfoCollection {
    height: 55px;
}

.notImportantContainerCollection p {
    text-align: center;
}

.notImportantContainerCollection p.nameStickersCollection {
    margin:0;
}

.imageCollectionImage {
    overflow:hidden;
    position: relative;
}

.containerServices{
    margin-bottom: 35px;
}

.servicesh2{
    margin-bottom: 25px;
}
.servicesh2 h2{
    text-align:center;
    text-transform: none;
}

.service-box {
    cursor: pointer;
    text-align: left;
    margin-bottom: 25px;
}

.service-box-text {
    background-color: #d8b545;
    padding: 5px;
}
.service-box-text p{
    color: #000;
}
.service-box-text p *{
    color: inherit;
}
.service-box-text p a{
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
}

.containerFluidMoreCollections {
    background-color: #f6f6f6;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.containerFluidMoreCollections a.collectionLink{
    color: inherit;
    display: inline-block;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .containerFluidMoreCollections a.collectionLink{
        font-size: 12px;
    }
}

.containerFluidPromotionsNews{
    background-color: #f6f6f6;
}

.containerPromotionsNews{
    text-align:center;
    margin-bottom: 30px;
}

.promotionsNewsh2{
    margin-bottom: 25px;
}

.containerPromo {
    cursor: pointer;
}

.bannerText{
    margin-left:5px;
}

.preFooterAbout{
    background-color: #C9C9C9;
}
.newAboutUs img{
    width: 100%;
}

.preFooterTags {
    margin-top: 15px;
    margin-bottom: 15px;
}

.preFooterWebs a {
    color: inherit;
    text-decoration: underline;
}

.preFooterWebs span {
    color: #ffffff;
}

.servicesTitle{
    margin-top:5px;
}

.bannerContentTitle {
    width:95%;
    margin:0 auto;
    text-align:center;
    padding-top: 10px;
}

.bannerTitle, .bannerTitle a{
    color:white;
    text-decoration: none;
    font-weight: bold;
}

.bannerTitle a:hover{
    text-decoration: none;
}

.bannerText{
    width:95%;
    margin:0 auto;
    margin-bottom:15px;
    text-align:center;
}

.contentVideoYoutube {
    background-color: #75bae8;
    height:100%;
}

.contentPromo {
    height:100%;
}

.contentClock{
    background-color: #e2b77a;
    height:100%;
}

.prefooterH2{
    color:white !important;
}

.playVideoYoutube {
    top: 33%;
    position: absolute;
    width: 100%;
    left: -1%;
    z-index: 1;
}

.imageVideo {
    position:relative;
}

.video {
    border: 0px;
}

.notImportantContainerCollection .divInfoCollection {
    float:left;
}

.notImportantContainerCollection p.nameStickersCollection {
    margin-top:5px;
}

/* To keep the same aspect ratio for all images */
.notImportantContainerCollection  .imageCollectionImageContainer {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 93.75% 0 0 0; /* 93.75% = 100 / (w / h) = 100 / (320 / 300) */
}
.notImportantContainerCollection  .imageCollectionImageContainer img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.containerRating {
    margin-bottom:20px;
}

@media (max-width: 767px) {
    /*MOBILE XS*/
    .notImportantContainerCollection p.stickersAvailableCollection {
        font-size:11px;
        margin-bottom:5px;
    }
    .notImportantContainerCollection .divInfoCollection {
        height: 60px !important;
    }
    .imageCollection:after, .imageCollection:before {
        opacity:0;
    }

    .containerBtnMoreCollection{
        margin-bottom: 20px;
    }

    .textServices{
        height:60px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .contentServices{
        margin-bottom: 50px;
    }

    .servicesPortrait {
        margin-bottom: 0px;
    }

    .containerVideoYoutube{
        margin-bottom: 20px;
    }

    .textPromotions{
        margin-bottom: 30px;
        font-size: 12px;
        color:white;
        height: 51px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .videoYoutube p {
        margin-top: 0px;
    }

    .outlet p {
        margin-top:0px;
    }

    .clock p {
        margin-top:0px;
    }

    .playVideoYoutube img {
        width: 17%;
    }
    .servicesTitle p a{
        font-weight: 700;
        color: #000000;
        font-size: 20px;
        border-bottom: 1px solid #D8D8D8;
    }
    .bottom-space {
        margin-bottom: 50px;
    }
    .home-about-country {
        text-decoration: none !important;
    }
}
@media (min-width: 768px) {
    /*TABLET SM*/
    .servicesTitle p a{
        font-weight: 700;
        color: #000000;
        font-size: 20px;
        border-bottom:1px solid #D8D8D8;
    }
    .imageCollection:after, .imageCollection:before {
        opacity:0;
    }

    .textServices{
        height:126px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 6;
        -moz-box-orient: vertical;
        -ms-line-clamp: 6;
        -ms-box-orient: vertical;
        -o-line-clamp: 6;
        -o-box-orient: vertical;
    }
    .containerVideoYoutube{
        margin-bottom: 35px;
    }
    .containerPromo:hover:after, .containerPromo:hover:before {
        opacity:0;
    }
    .textPromotions{
        margin-bottom: 30px;
        font-size: 12px;
        color:white;
        height: 51px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .contentServices{
        margin-bottom: 25px;
    }

    .videoYoutube p {
        margin-top: 15px;
    }

    .outlet p {
        margin-top:15px;
    }

    .clock p {
        margin-top:15px;
    }

    .iconDropdown {
        margin-left: 76px;
    }

    .containerDropdownTheme {
        margin-top:-15px;
        padding:5px;  /* dejamos una separación entre borde y texto */
    }
}
@media (min-width: 992px) {
    /*PC MD-LG*/
    .textServices{
        height:65px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }
    .containerVideoYoutube{
        margin-bottom: 10px;
    }
    .containerPromo:after, .containerPromo:before {
        position:absolute;
        opacity:0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .containerPromo:after {
        content:'\A';
        width: 212px;
        height: 257px;
        margin-left:15px;
        top:0; left:0;
        background:rgba(0,0,0,0.6);
    }
    .containerPromo.bigPromo:after {
        width: 455px;
        height: 259px;
    }
    .containerPromo:hover:after, .containerPromo:hover:before {
        opacity:0.6;
    }
    .imageVideo:after, .containerPromo:before {
        position:absolute;
        opacity:0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .imageVideo:after {
        content:'\A';
        width: 455px;
        height: 259px;
        top:0; left:0;
        background:rgba(0,0,0,0.6);
    }
    .imageVideo:hover:after, .containerPromo:hover:before {
        opacity:0.6;
    }
    .textPromotions{
        margin-bottom: 30px;
        font-size: 12px;
        color:white;
        height: 51px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .contentVideoYoutube {
        height:118px;
    }
}

@media (min-width: 1200px) {
    .imageVideo:after {
        width:554px;
        height:316px;
    }
    .containerPromo:after {
        width:263px;
        height:316px;
    }
    .containerPromo.bigPromo:after {
        width:555px;
    }
    .contentVideoYoutube {
        height:100%;
    }
}

#mj-widget-frame {
    height: 550px;
}
@media (min-width: 768px) {
    #mj-widget-frame {
        height: 450px;
    }
}
@media (min-width: 992px) {
    #mj-widget-frame {
        height: 320px;
    }
}
.bottom-space {
    margin-bottom: 50px;
}
.home-about-country {
    text-decoration: none !important;
}

img.lazy-load-img.flag {
    background-color: #eee;
    min-width: 21px;
    min-height: 21px;
    border-radius: 50%;
}

.newsletter-home-name{
    /*border: 2px solid rgb(225, 225, 230);*/
    width: 100%;
    border: 1px solid;
}

.newsletter-home .title {
    color:#000;
    display: block;
    font-size: 20px;
}
.newsletter-home .title b {
    font-size: 20px;
    color: #000 !important;
}
@media (max-width: 768px) {
    .newsletter-home .title {
        font-size: 15px;
    }
    .newsletter-home .title b {
        font-size: 15px;
    }
}

.newsletter-banner .font-size-12 {
    font-size: 12px !important;
}
.newsletter-banner .font-size-12 * {
    font-size: 12px !important;
}

.customText {
    border: 1px solid #000 !important;
    padding-left: 5px;
    &.disabled-resize {
        display: block;
        overflow: hidden;
        resize: none;
    }
    &.limit-rows {
        overflow-y: auto;
    }
}
