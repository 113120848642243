#title-container {
    text-align: center;
    overflow: hidden;
}
#title-container h2 {
    text-transform: none;
    font-weight: normal;
    margin-top: 0;
    font-size: inherit;
}
#hero-container {
    margin: 15px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    text-align: center;
}
#hero-container .hero-content {
    overflow: hidden;
    cursor: pointer;
}#hero-container .hero-content.no-clickable {
     cursor: default;
 }
#hero-container .hero-content .hero-image {
    width: 100%;
    display: block;
    background-repeat:no-repeat;
    background-position: center;
    -webkit-background-size:cover;
    background-size:cover;
    transition-duration: .5s;
}

#hero-container .hero-content .hero-image img {
    width: 100%;
    aspect-ratio: auto 3.5;
}

@media (max-width: 767px) {
    #hero-container .hero-content .hero-image.hidden-xs {
        background-image: none!important;
    }

    /*#hero-container .hero-content .hero-image {
        height: 350px;
    }*/
}
@media (min-width: 768px) {
    #hero-container .hero-content .hero-image.visible-xs {
        background-image: none!important;
    }
    /*#hero-container .hero-content .hero-image{
        background-size: 100% 140%
    }*/
}
#hero-container .hero-content .hero-text {
    color: #000;
    /*margin: 20px 0;*/
    padding: 20px 1px 30px;
}
#hero-container .hero-content .hero-text .hero-text-title{
    color: #000;
    font-weight:bold;
    font-size:17px;
    margin-right:10px;
    text-decoration: none;
}

.bannerContentTitle p {
    color:white;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 1;
    -moz-box-orient: vertical;
    -ms-line-clamp: 1;
    -ms-box-orient: vertical;
    -o-line-clamp: 1;
    -o-box-orient: vertical;
}

.collectionTitle{
    margin-bottom: 25px;
    text-align:center;
}

.contentCollection {
    position: relative;
}

.textCollection {
    display: table;
    width: 100%;
    height: 100%;
    padding:10px;
}

.containerCollection{
    margin-bottom: 25px;
    cursor: pointer;
}

.containerCollection:hover .imageCollectionImage>img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.containerCollection a, .containerCollection p {
    color: #ffffff;
}

.containerCollection a:hover {
    text-decoration: none;
}

.imageCollectionImage>img {
    width: 100%;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.importantContainerCollection p.nameStickersCollection {
    text-align: left;
    float:left;
    margin-top: 4px;
    margin-left: 7px;
}

.importantContainerCollection p.stickersAvailableCollection {
    text-align: right;
    margin-right: 7px;
    float:right;
    margin-top:4px;
}

.importantContainerCollection .divInfoCollection {
    height: 35px;
}

.notImportantContainerCollection .divInfoCollection {
    height: 55px;
}

.notImportantContainerCollection p {
    text-align: center;
}

.notImportantContainerCollection p.nameStickersCollection {
    margin:0;
}

.imageCollectionImage {
    overflow:hidden;
    position: relative;
}

.containerServices{
    margin-bottom: 35px;
}

.servicesh2{
    margin-bottom: 25px;
}
.servicesh2 h2{
    text-align:center;
    text-transform: none;
}

.service-box {
    cursor: pointer;
    text-align: left;
    margin-bottom: 25px;
}

.service-box-text {
    background-color: #d8b545;
    padding: 5px;
}
.service-box-text p{
    color: #000;
}
.service-box-text p *{
    color: inherit;
}
.service-box-text p a{
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
}

.containerFluidMoreCollections {
    background-color: #f6f6f6;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.containerFluidMoreCollections a.collectionLink{
    color: inherit;
    display: inline-block;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .containerFluidMoreCollections a.collectionLink{
        font-size: 12px;
    }
}

.containerFluidPromotionsNews{
    background-color: #f6f6f6;
}

.containerPromotionsNews{
    text-align:center;
    margin-bottom: 30px;
}

.promotionsNewsh2{
    margin-bottom: 25px;
}

.containerPromo {
    cursor: pointer;
}

.bannerText{
    margin-left:5px;
}

.preFooterAbout{
    background-color: #C9C9C9;
}
.newAboutUs img{
    width: 100%;
}

.preFooterTags {
    margin-top: 15px;
    margin-bottom: 15px;
}

.preFooterWebs a {
    color: inherit;
    text-decoration: underline;
}

.preFooterWebs span {
    color: #ffffff;
}

.servicesTitle{
    margin-top:5px;
}

.bannerContentTitle {
    width:95%;
    margin:0 auto;
    text-align:center;
    padding-top: 10px;
}

.bannerTitle, .bannerTitle a{
    color:white;
    text-decoration: none;
    font-weight: bold;
}

.bannerTitle a:hover{
    text-decoration: none;
}

.bannerText{
    width:95%;
    margin:0 auto;
    margin-bottom:15px;
    text-align:center;
}

.contentVideoYoutube {
    background-color: #75bae8;
    height:100%;
}

.contentPromo {
    height:100%;
}

.contentClock{
    background-color: #e2b77a;
    height:100%;
}

.prefooterH2{
    color:white !important;
}

.playVideoYoutube {
    top: 33%;
    position: absolute;
    width: 100%;
    left: -1%;
    z-index: 1;
}

.imageVideo {
    position:relative;
}

.video {
    border: 0px;
}

.notImportantContainerCollection .divInfoCollection {
    float:left;
}

.notImportantContainerCollection p.nameStickersCollection {
    margin-top:5px;
}

/* To keep the same aspect ratio for all images */
.notImportantContainerCollection  .imageCollectionImageContainer {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 93.75% 0 0 0; /* 93.75% = 100 / (w / h) = 100 / (320 / 300) */
}
.notImportantContainerCollection  .imageCollectionImageContainer img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.containerRating {
    margin-bottom:20px;
}

@media (max-width: 767px) {
    /*MOBILE XS*/
    .notImportantContainerCollection p.stickersAvailableCollection {
        font-size:11px;
        margin-bottom:5px;
    }
    .notImportantContainerCollection .divInfoCollection {
        height: 60px !important;
    }
    .imageCollection:after, .imageCollection:before {
        opacity:0;
    }

    .containerBtnMoreCollection{
        margin-bottom: 20px;
    }

    .textServices{
        height:60px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .contentServices{
        margin-bottom: 50px;
    }

    .servicesPortrait {
        margin-bottom: 0px;
    }

    .containerVideoYoutube{
        margin-bottom: 20px;
    }

    .textPromotions{
        margin-bottom: 30px;
        font-size: 12px;
        color:white;
        height: 51px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .videoYoutube p {
        margin-top: 0px;
    }

    .outlet p {
        margin-top:0px;
    }

    .clock p {
        margin-top:0px;
    }

    .playVideoYoutube img {
        width: 17%;
    }
    .servicesTitle p a{
        font-weight: 700;
        color: #000000;
        font-size: 20px;
        border-bottom: 1px solid #D8D8D8;
    }
    .bottom-space {
        margin-bottom: 50px;
    }
    .home-about-country {
        text-decoration: none !important;
    }
}
@media (min-width: 768px) {
    /*TABLET SM*/
    .servicesTitle p a{
        font-weight: 700;
        color: #000000;
        font-size: 20px;
        border-bottom:1px solid #D8D8D8;
    }
    .imageCollection:after, .imageCollection:before {
        opacity:0;
    }

    .textServices{
        height:126px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 6;
        -moz-box-orient: vertical;
        -ms-line-clamp: 6;
        -ms-box-orient: vertical;
        -o-line-clamp: 6;
        -o-box-orient: vertical;
    }
    .containerVideoYoutube{
        margin-bottom: 35px;
    }
    .containerPromo:hover:after, .containerPromo:hover:before {
        opacity:0;
    }
    .textPromotions{
        margin-bottom: 30px;
        font-size: 12px;
        color:white;
        height: 51px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .contentServices{
        margin-bottom: 25px;
    }

    .videoYoutube p {
        margin-top: 15px;
    }

    .outlet p {
        margin-top:15px;
    }

    .clock p {
        margin-top:15px;
    }

    .iconDropdown {
        margin-left: 76px;
    }

    .containerDropdownTheme {
        margin-top:-15px;
        padding:5px;  /* dejamos una separación entre borde y texto */
    }
}
@media (min-width: 992px) {
    /*PC MD-LG*/
    .textServices{
        height:65px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }
    .containerVideoYoutube{
        margin-bottom: 10px;
    }
    .containerPromo:after, .containerPromo:before {
        position:absolute;
        opacity:0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .containerPromo:after {
        content:'\A';
        width: 212px;
        height: 257px;
        margin-left:15px;
        top:0; left:0;
        background:rgba(0,0,0,0.6);
    }
    .containerPromo.bigPromo:after {
        width: 455px;
        height: 259px;
    }
    .containerPromo:hover:after, .containerPromo:hover:before {
        opacity:0.6;
    }
    .imageVideo:after, .containerPromo:before {
        position:absolute;
        opacity:0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .imageVideo:after {
        content:'\A';
        width: 455px;
        height: 259px;
        top:0; left:0;
        background:rgba(0,0,0,0.6);
    }
    .imageVideo:hover:after, .containerPromo:hover:before {
        opacity:0.6;
    }
    .textPromotions{
        margin-bottom: 30px;
        font-size: 12px;
        color:white;
        height: 51px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .contentVideoYoutube {
        height:118px;
    }
}

@media (min-width: 1200px) {
    .imageVideo:after {
        width:554px;
        height:316px;
    }
    .containerPromo:after {
        width:263px;
        height:316px;
    }
    .containerPromo.bigPromo:after {
        width:555px;
    }
    .contentVideoYoutube {
        height:100%;
    }
}

#mj-widget-frame {
    height: 550px;
}
@media (min-width: 768px) {
    #mj-widget-frame {
        height: 450px;
    }
}
@media (min-width: 992px) {
    #mj-widget-frame {
        height: 320px;
    }
}
.bottom-space {
    margin-bottom: 50px;
}
.home-about-country {
    text-decoration: none !important;
}

img.lazy-load-img.flag {
    background-color: #eee;
    min-width: 21px;
    min-height: 21px;
    border-radius: 50%;
}

.newsletter-home-name{
    /*border: 2px solid rgb(225, 225, 230);*/
    width: 100%;
    border: 1px solid;
}

.newsletter-home .title {
    color:#000;
    display: block;
    font-size: 20px;
}
.newsletter-home .title b {
    font-size: 20px;
    color: #000 !important;
}
@media (max-width: 768px) {
    .newsletter-home .title {
        font-size: 15px;
    }
    .newsletter-home .title b {
        font-size: 15px;
    }
}

.newsletter-banner .font-size-12 {
    font-size: 12px !important;
}
.newsletter-banner .font-size-12 * {
    font-size: 12px !important;
}

.customText {
    border: 1px solid #000 !important;
    padding-left: 5px;
    &.disabled-resize {
        display: block;
        overflow: hidden;
        resize: none;
    }
    &.limit-rows {
        overflow-y: auto;
    }
}

.item-offers {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.item-offers-item {
    float: right;
    margin-bottom: 5px;
    padding: 3px 10px;
    font-weight: bold;
    text-align: center;
}
.item-offers-item.offer-freeShipping {
    background-color: rgba(0, 0, 0, 0.8);
    color: #F7AE1C;
    text-transform: uppercase;
}
.item-offers-item.offer-discount {
    background-color: rgba(247, 174, 28, 0.8);
    color: #ffffff;
}
.item-offers-oldPrice {
    text-decoration: line-through;
    color: #7f7f7f;
    margin-left: 15px;
}

/* DEPRECATED */

.reviewStars{
    background-image: url(/build/images/stars.ff740e97.png);
    display: block;
}
.reviewStars.starsContainer-overall{
    width: 154px;
    height: 26px;
    background-position: -2px -52px;
}
.reviewStars.starsContainer-review{
    width: 95px;
    height: 16px;
    background-position: -2px -82px;
}
.reviewStars.starsRating-overall{
    width: 154px;
    height: 26px;
    background-position: -2px -2px;
}
.reviewStars.starsRating-review{
    width: 95px;
    height: 16px;
    background-position: -2px -32px;
}
.infoRating-overall{
    margin-top: 10px;
    text-align: right;
}
.reviewItem{
    margin-bottom: 20px;
}
.reviewItem .reviewDate{
    color: #2196f3;
}
.reviewItem .reviewImage-link{
    text-decoration: none;
    vertical-align: super;
    margin: -10px 10px 0 0;
    display: inline-block;
}
.reviewItem .reviewImage-link .reviewImage-icon{
    color: #000000;
    font-size: 20px;
}
.reviewItem .reviewImage-link .reviewImage-text{
    text-decoration: underline;
    vertical-align: super;
    -webkit-transition: color .2s;
    -moz-transition: color .2s;
    -ms-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
}
.reviewItem .reviewImage-link:hover .reviewImage-text{
    color: #2196f3;
}

.spanRatingValue {
    font-weight: 700;
}

.productLink {
    font-size:12px;
}

.responseReviewp {
    font-style:italic;
}

.contentReviews {
    margin-bottom: 20px;
}

.containerReviewItem {
    margin-bottom: 15px;
    background-color: #ececec;
    border: 1px solid #d7d7d7;
    padding: 10px 15px;
}

.titleInfoReviewItem p {
    font-size:13px;
    margin: 0;
    display: inline;
    vertical-align: text-top;
}

.containerStarsValoration {
    text-align:right;
}

.containerStarsValoration .spanValoration {
    display:inline-block;
    margin-left: 10px;
}

.containerReviewStars {
    display:inline-block;
}

.glyphicon-comment {
    font-size:27px;
}

.containerResponseAdminReview {
    margin-top:20px;
    margin-bottom:10px;
}

.containerLinks {
    margin-top:15px;
}

.containerLinks span {
    color:#009587;
}

.contentImagesReview {
    margin-top:10px;
}

.view-image-container:not(:last-child) {
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    /*MOBILE XS*/
    .spanReviewDate {
        font-size:12px;
    }
}

@media (min-width: 768px) {
    /*TABLET SM*/
    .imgTenquality {
        float:right;
    }
    .spanReviewDate {
        float:left;
        font-size:13px;
    }

    .infoReviewsp {
        font-size:12px;
        margin-top: 10px;
    }

    .responseReviewp {
        margin-left:15px;
    }

    .reviewp {
        margin-left:0px;
    }
}
@media (min-width: 992px) {
    /*PC MD-LG*/
    .infoReviewsp {
        font-size:15px;
        margin-top: 10px;
    }

    .responseReviewp {
        margin-left:0px;
    }

    .reviewp {
        margin-left:-15px;
    }
}
.top-facet-reviews {
    margin: 10px 0;
}
.top-facet-review {
    padding: 5px 0;
}
.top-facet-review a {
    color: #2196f3;
    text-decoration: underline;
}
.top-facet-rating {
    text-align: right;
}

.review-text-row {
    margin-top: 10px;
}
.promo-big-banner {
    position: relative;
    display: block;
}
.promo-big-banner .promo-big-content {
    height: 100%;
    overflow: hidden;
    position:absolute;
    top: 0;
    left: 28%;
    right: 18%;
}
.promo-big-banner .promo-big-content .promo-big-text {
    height: 100%;
    display: table;
}
.promo-big-banner .promo-big-content .promo-big-text p {
    display: table-cell;
    vertical-align: middle;
}
.event-banner {
    background-color: #f7ae1c;
    cursor: pointer;
}
.results-event-banner {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.results-event-banner a {
    color: #000;
    font-weight: bold;
}
.event-banner-btn {
    padding: 6px 12px;
}
.product-page-event-banner {
    padding: 20px;
    cursor: pointer;
}
.product-page-event-banner a {
    color: #000;
    font-weight: bold;
}
.product-page-event-banner .event-description {
    color: #000;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.swiper-banner-home {
    position: relative;
}
.swiper-banner-home .swiper-button-prev, .swiper-banner-home .swiper-button-next {
    position: absolute;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-3 * var(--swiper-navigation-size) / 2);
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    --swiper-navigation-size: 30px;
}

.swiper-banner-home .swiper-button-disabled {
    display: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
}

.swiper-banner-home .swiper-pagination{
    bottom: 0;
    background: #fff;
    padding: 5px 1px;
    z-index: 1;
}
#ts-title {
  margin-bottom: 25px;
  text-align: center;
}

.ts-row .ts-item-title {
  padding: 0.25em 0 0 0;
}

.ts-row .col-md-6 .ts-item-title {
  height: 35px;
}

.ts-row .col-md-6 .ts-item-title .ts-item-link {
  float: left;
  text-align: left;
  margin: 4px 0 10px 7px;
}

.ts-row .col-md-6 .ts-item-title .ts-item-count {
  float: right;
  text-align: right;
  margin: 4px 7px 10px 0;
}

.ts-row .col-md-3,
.ts-row .col-md-4 {
  position: relative;
  padding-bottom: 55px;
}

.ts-row .col-md-3 .ts-item-title,
.ts-row .col-md-4 .ts-item-title {
  min-height: 55px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 15px;
}

.ts-row .ts-item {
  cursor: pointer;
  margin-bottom: 25px;
}

.ts-row .ts-item .ts-item-title {
  overflow: hidden;
}

.ts-row .ts-item .ts-item-title .ts-item-link {
  color: inherit;
  text-decoration: none;
}

.ts-row .ts-item .ts-item-title .ts-item-count {
  color: inherit;
}


#product-finder-label {
  right: -55px;
  line-height: 30px;
  width: 140px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  background-color: #000;
  position: fixed;
  top: 40%;
  z-index: 11;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  box-shadow: -2px -2px 4px 0 rgba(0, 0, 0, 0.4);
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

#product-finder-label.pf-label-hidden {
  transform: rotate(-90deg) translate(0, 40px);
  -webkit-transform: rotate(-90deg) translate(0, 40px);
  -moz-transform: rotate(-90deg) translate(0, 40px);
  -ms-transform: rotate(-90deg) translate(0, 40px);
  -o-transform: rotate(-90deg) translate(0, 40px);
}

#product-finder-label .pfl-txt {
  font-size: 13px;
  font-weight: bold;
  color: #FFF;
}

#product-finder-label .tvi-product-finder {
  color: #FFF;
  font-size: 25px;
  vertical-align: middle;
  display: inline-block;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

#product-finder-banner {
  padding: 15px;
  text-align: center;
  margin-bottom: 25px;
  background: url(/build/images/banner_mobile.b7ecc888.webp) center;
}

@media (min-width: 992px) {
  #product-finder-banner {
    padding: 35px 50px 15px;
    text-align: left;
    background: url(/build/images/banner_desktop.472cc94a.webp) center;
  }
}

#product-finder-banner * {
  color: #FFF;
}

#product-finder-banner .pfb-title {
  font-size: 25px;
  font-weight: bold;
}

#product-finder-banner .pfb-title .tvi-product-finder {
  font-size: 50px;
  vertical-align: middle;
}

@media (min-width: 992px) {
  #product-finder-banner .pfb-title {
    font-size: 45px;
    text-align: center;
    margin: 50px 0;
  }

  #product-finder-banner .pfb-title .tvi-product-finder {
    font-size: 75px;
  }
}

#product-finder-banner .pfb-subtitle {
  font-size: 20px;
  font-weight: bold;
  margin: 0 35px;
}

@media (min-width: 992px) {
  #product-finder-banner .pfb-subtitle {
    margin: initial;
    font-size: 25px;
  }
}

#product-finder-banner .pfb-btn {
  background-color: #FFF;
  color: #000 !important;
  display: inline-block;
  padding: 10px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}

body.overflow-hidden {
  overflow: hidden !important;
}

#product-finder {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -150%;
  width: 100%;
  z-index: 950;
  background: url(/build/images/bg_mobile.a7e734f6.webp) center;
  background-size: cover;
  transition: right .5s;
}

@media (min-width: 992px) {
  #product-finder {
    width: 64%;
    max-width: 800px;
    background: url(/build/images/bg_desktop.a3fbfe51.webp) center;
    background-size: cover;
  }
}

#product-finder.pf-open {
  right: 0;
}

#product-finder #pf-header {
  height: 75px;
  line-height: 75px;
  position: relative;
  padding: 0 15px;
}

#product-finder #pf-header #pf-title {
  font-weight: bold;
  color: #FFF;
  font-size: 20px;
}

#product-finder #pf-header #pf-buttons {
  position: absolute;
  top: 0;
  right: 15px;
}

#product-finder #pf-header #pf-buttons * {
  color: #FFF;
  margin-left: 5px;
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
}

#product-finder #pf-header .pf-tooltip {
  position: relative;
}

#product-finder #pf-header .pf-tooltip:before {
  content: attr(data-mobile-text);
  position: absolute;
  bottom: -55px;
  transform: translateY(-50%);
  right: -43px;
  margin-left: 15px;
  width: 295px;
  padding: 9px 4px;
  border: 1px solid #F5F6F8;
  background: #F5F6F8;
  color: #000;
  text-align: center;
  font-size: 13px;
  line-height: 1;
  z-index: 100000;
  -webkit-box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.32);
  box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.32);
  display: none;
}

@media (min-width: 992px) {
  #product-finder #pf-header .pf-tooltip:before {
    content: attr(data-text);
  }
}

#product-finder #pf-header .pf-tooltip:after {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  border: 1px solid;
  border-color: #F5F6F8 transparent transparent #F5F6F8;
  background: #F5F6F8;
  top: 32px;
  transform: translateY(-50%) rotate(45deg);
  display: none;
}

#product-finder #pf-header .pf-tooltip:hover:before,
#product-finder #pf-header .pf-tooltip:hover:after {
  display: block;
}

#product-finder #pf-header .pf-tooltip.show-tip:before,
#product-finder #pf-header .pf-tooltip.show-tip:after {
  display: block;
}

#product-finder #pf-body {
  position: absolute;
  top: 75px;
  bottom: 75px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

#product-finder #pf-body.bg-white {
  background-color: #FFF;
}

#product-finder #pf-body .pf-breadcrumb {
  color: #696969 !important;
  font-size: 14px;
  margin-top: 0.7rem !important;
  margin-left: 1rem !important;
  text-align: left;
}

#product-finder #pf-body .pf-breadcrumb span:not(:last-child):after {
  content: "\BB";
  padding: 0 10px;
}

#product-finder #pf-body #pf-start-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15px;
  width: 100%;
}

#product-finder #pf-body #pf-start-content #pfs-title {
  color: #FFF;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  #product-finder #pf-body #pf-start-content #pfs-title {
    font-size: 50px;
  }
}

#product-finder #pf-body #pf-start-content #pfs-text {
  color: #FFF;
  font-weight: bold;
  font-size: 18px;
}

@media (min-width: 992px) {
  #product-finder #pf-body #pf-start-content #pfs-text {
    width: 50%;
    margin: 0 auto;
  }
}

#product-finder #pf-body #pf-start-content #pfs-btn {
  background-color: #FFF;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  padding: 10px 30px;
  margin-top: 25px;
  font-size: 18px;
}

#product-finder #pf-body #pf-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  z-index: 1;
  opacity: .8;
}

#product-finder #pf-body #pf-loading span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  font-size: 100px;
  display: block;
  width: 100%;
}

#product-finder #pf-body #pf-loading span.pf-spin {
  -webkit-animation: pf-spin 2s infinite linear;
  animation: pf-spin 2s infinite linear;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

#product-finder #pf-body #pf-working {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  z-index: 10;
  opacity: 1;
}

#product-finder #pf-body #pf-working .working-message {
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  color: #000;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin-top: 3rem !important;
}

#product-finder #pf-body #pf-working span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  font-size: 100px;
  display: block;
  width: 100%;
  text-align: center;
}

#product-finder #pf-body #pf-working span.pf-spin {
  -webkit-animation: pf-spin 2s infinite linear;
  animation: pf-spin 2s infinite linear;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

#product-finder #pf-body .pf-answers,
#product-finder #pf-body .pf-results {
  background-color: #FFF;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  transition: top .5s;
  overflow-y: auto;
  overflow-x: hidden;
}

#product-finder #pf-body .pf-answers.next,
#product-finder #pf-body .pf-results.next {
  top: 200%;
}

#product-finder #pf-body .pf-answers.prev,
#product-finder #pf-body .pf-results.prev {
  top: -200%;
}

#product-finder #pf-body .pf-answers .pfa-content,
#product-finder #pf-body .pf-answers .pfr-content,
#product-finder #pf-body .pf-results .pfa-content,
#product-finder #pf-body .pf-results .pfr-content {
  background-color: #FFF;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15px;
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfa-content,
  #product-finder #pf-body .pf-answers .pfr-content,
  #product-finder #pf-body .pf-results .pfa-content,
  #product-finder #pf-body .pf-results .pfr-content {
    top: 0;
    transform: none;
  }
}

#product-finder #pf-body .pf-answers .pfa-content .pfa-txt,
#product-finder #pf-body .pf-answers .pfa-content .pfr-txt,
#product-finder #pf-body .pf-answers .pfr-content .pfa-txt,
#product-finder #pf-body .pf-answers .pfr-content .pfr-txt,
#product-finder #pf-body .pf-results .pfa-content .pfa-txt,
#product-finder #pf-body .pf-results .pfa-content .pfr-txt,
#product-finder #pf-body .pf-results .pfr-content .pfa-txt,
#product-finder #pf-body .pf-results .pfr-content .pfr-txt {
  color: #000;
  font-size: 20px;
  margin: 30px 0;
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfa-content .pfa-txt,
  #product-finder #pf-body .pf-answers .pfa-content .pfr-txt,
  #product-finder #pf-body .pf-answers .pfr-content .pfa-txt,
  #product-finder #pf-body .pf-answers .pfr-content .pfr-txt,
  #product-finder #pf-body .pf-results .pfa-content .pfa-txt,
  #product-finder #pf-body .pf-results .pfa-content .pfr-txt,
  #product-finder #pf-body .pf-results .pfr-content .pfa-txt,
  #product-finder #pf-body .pf-results .pfr-content .pfr-txt {
    font-size: 30px;
    margin: 50px 15px;
  }
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfa-content .pfa-txt,
  #product-finder #pf-body .pf-answers .pfa-content .pfr-txt,
  #product-finder #pf-body .pf-answers .pfr-content .pfa-txt,
  #product-finder #pf-body .pf-answers .pfr-content .pfr-txt,
  #product-finder #pf-body .pf-results .pfa-content .pfa-txt,
  #product-finder #pf-body .pf-results .pfa-content .pfr-txt,
  #product-finder #pf-body .pf-results .pfr-content .pfa-txt,
  #product-finder #pf-body .pf-results .pfr-content .pfr-txt {
    text-align: left;
  }
}

#product-finder #pf-body .pf-answers .pfa-content .pfa-answer,
#product-finder #pf-body .pf-answers .pfr-content .pfa-answer,
#product-finder #pf-body .pf-results .pfa-content .pfa-answer,
#product-finder #pf-body .pf-results .pfr-content .pfa-answer {
  background-color: #e0e0e0;
  margin: 20px 15%;
  padding: 15px 20px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s;
}

#product-finder #pf-body .pf-answers.current .pfa-answer:hover,
#product-finder #pf-body .pf-results.current .pfa-answer:hover {
  background-color: #000;
  color: #FFF;
}

#product-finder #pf-body .pf-answers .pfr-content #pfp-arrows,
#product-finder #pf-body .pf-results .pfr-content #pfp-arrows {
  position: absolute;
  top: 215px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 85%;
  z-index: 1;
}

@media (min-width: 768px) {
  #product-finder #pf-body .pf-answers .pfr-content #pfp-arrows,
  #product-finder #pf-body .pf-results .pfr-content #pfp-arrows {
    width: 94%;
    top: 265px !important;
  }
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content #pfp-arrows,
  #product-finder #pf-body .pf-results .pfr-content #pfp-arrows {
    top: 320px !important;
    width: 95%;
  }
}

#product-finder #pf-body .pf-answers .pfr-content #pfp-arrows span,
#product-finder #pf-body .pf-results .pfr-content #pfp-arrows span {
  position: absolute;
  font-size: 35px;
  color: #000;
  cursor: pointer;
}

#product-finder #pf-body .pf-answers .pfr-content #pfp-arrows span#pfpa-prev,
#product-finder #pf-body .pf-results .pfr-content #pfp-arrows span#pfpa-prev {
  font-size: 25px;
  left: 0;
}

#product-finder #pf-body .pf-answers .pfr-content #pfp-arrows span#pfpa-next,
#product-finder #pf-body .pf-results .pfr-content #pfp-arrows span#pfpa-next {
  font-size: 25px;
  right: 0;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product,
#product-finder #pf-body .pf-results .pfr-content .pfp-product {
  position: absolute;
  top: 350px !important;
  width: 65%;
  transform: translateY(-50%) translateX(-50%);
  transition: left .5s;
  /*@media (max-width: 1199px) and (min-width: $break-md) {
            #productLabels {
              bottom: 28%;
            }
          }
          @media (max-width: $break-md) {
            #productLabels {
              bottom: 25%;
            }
          }*/
}

@media (min-width: 768px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product {
    top: 285px !important;
    width: 85%;
  }
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product {
    top: 345px !important;
    width: 85%;
  }
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product a,
#product-finder #pf-body .pf-results .pfr-content .pfp-product a {
  text-decoration: none;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product.current,
#product-finder #pf-body .pf-results .pfr-content .pfp-product.current {
  left: 50%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product.next,
#product-finder #pf-body .pf-results .pfr-content .pfp-product.next {
  left: 150%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product.prev,
#product-finder #pf-body .pf-results .pfr-content .pfp-product.prev {
  left: -150%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product img,
#product-finder #pf-body .pf-results .pfr-content .pfp-product img {
  width: 100%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .position-relative,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .position-relative {
  position: relative;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .h-100,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .h-100 {
  height: 100%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .w-100,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .w-100 {
  width: 100%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .d-flex.flex-column,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .d-flex.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-title,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-title {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 7px;
  /*margin-top: 10px;*/
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-desc,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-desc {
  height: 80px;
  line-height: 1.2;
  text-align: justify;
  font-size: 15px;
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-desc,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-desc {
    height: 80px;
  }
}

@media (max-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-desc,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-desc {
    margin-top: 10px;
  }
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-price,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-price {
  width: 100%;
  color: #000;
  font-weight: bold;
  position: relative;
  bottom: 50px;
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-price,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-price {
    right: 0;
    position: absolute;
    bottom: 47px;
  }
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-price .pfpp-title-mobile,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-price .pfpp-title-mobile {
  float: left;
  text-align: left;
  width: 75%;
  overflow: hidden;
  font-weight: bold;
  color: #000;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-price .pfpp-price-value,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-price .pfpp-price-value {
  width: 25%;
  float: right;
  text-align: right;
  color: #000;
  font-weight: bold;
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-price .pfpp-price-value,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-price .pfpp-price-value {
    width: 100%;
  }
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-btn,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-btn {
  position: absolute;
  bottom: 0;
  color: #000;
  border: 1px solid;
  /*margin-top: 35px;*/
  padding: 10px;
  font-weight: bold;
  width: 100%;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-btn a,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-btn a {
  text-align: center;
  color: #000;
  font-weight: bold;
  text-decoration: none !important;
  width: 100%;
  font-size: 15px !important;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pfpp-btn .glyphicon,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pfpp-btn .glyphicon {
  color: #000;
  font-size: 16px;
  vertical-align: middle;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pf-advantages-block,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pf-advantages-block {
  height: 100px !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 992px) {
  #product-finder #pf-body .pf-answers .pfr-content .pfp-product .pf-advantages-block,
  #product-finder #pf-body .pf-results .pfr-content .pfp-product .pf-advantages-block {
    height: 115px !important;
  }
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pf-advantages-block .splide__slide,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pf-advantages-block .splide__slide {
  text-align: left;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .pf-advantages-block .innerTextAdvantage,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .pf-advantages-block .innerTextAdvantage {
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .top-label,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .top-label {
  margin-bottom: 5px;
  padding: 3px 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .top-label.new,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .top-label.new {
  background-color: #0BCB3F;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .top-label.best-seller,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .top-label.best-seller {
  background-color: #ff9800;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-customizable-label,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-customizable-label {
  cursor: pointer;
  width: 100%;
  z-index: 10;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-customizable-label .item-customizable-item,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-customizable-label .item-customizable-item {
  float: left;
  margin-bottom: 5px;
  padding: 3px 10px;
  font-weight: bold;
  text-align: center;
  background-color: #1565C0;
  color: #ffffff;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-offer-label,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-offer-label {
  width: 100%;
  z-index: 10;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-offer-label div,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-offer-label div {
  float: left;
  margin-bottom: 5px;
  padding: 3px 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-offer-label div.item-free-shipping,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-offer-label div.item-free-shipping {
  background-color: #000;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-offer-label div.item-discount,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-offer-label div.item-discount {
  background-color: #ff0000;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product .item-offer-label div.item-black-discount,
#product-finder #pf-body .pf-results .pfr-content .pfp-product .item-offer-label div.item-black-discount {
  background-color: #000;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product #productLabels,
#product-finder #pf-body .pf-results .pfr-content .pfp-product #productLabels {
  z-index: 10;
  position: absolute;
  bottom: 0;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product #productLabels *,
#product-finder #pf-body .pf-results .pfr-content .pfp-product #productLabels * {
  font-size: 11px;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product #productLabelsTop,
#product-finder #pf-body .pf-results .pfr-content .pfp-product #productLabelsTop {
  z-index: 10;
  position: absolute;
  top: 11px;
}

#product-finder #pf-body .pf-answers .pfr-content .pfp-product #productLabelsTop *,
#product-finder #pf-body .pf-results .pfr-content .pfp-product #productLabelsTop * {
  font-size: 11px;
}

#product-finder #pf-footer {
  position: absolute;
  bottom: 0;
  height: 75px;
  width: 100%;
  padding: 0 15px;
  background-color: #FFF;
}

#product-finder #pf-footer .pf-pagelogo {
  position: absolute;
  bottom: 30px;
  right: 20px;
  height: 20px;
}

@keyframes pf-spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}


