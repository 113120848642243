#ts-title {
  margin-bottom: 25px;
  text-align: center;
}
.ts-row {

  .ts-item-title {
    padding: 0.25em 0 0 0;
  }

  .col-md-6 {
    .ts-item-title {
      height: 35px;

      .ts-item-link {
        float: left;
        text-align: left;
        margin: 4px 0 10px 7px;
      }

      .ts-item-count {
        float: right;
        text-align: right;
        margin: 4px 7px 10px 0;
      }
    }
  }
  .col-md-3, .col-md-4 {
    position: relative;
    padding-bottom: 55px;

    .ts-item-title {
      min-height: 55px;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 15px;
    }
  }

  .ts-item {
    cursor: pointer;
    margin-bottom: 25px;

    .ts-item-title {
      overflow: hidden;
      .ts-item-link {
        color: inherit;
        text-decoration: none;
      }

      .ts-item-count {
        color: inherit;
      }
    }
  }
}