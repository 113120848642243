.item-offers {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.item-offers-item {
    float: right;
    margin-bottom: 5px;
    padding: 3px 10px;
    font-weight: bold;
    text-align: center;
}
.item-offers-item.offer-freeShipping {
    background-color: rgba(0, 0, 0, 0.8);
    color: #F7AE1C;
    text-transform: uppercase;
}
.item-offers-item.offer-discount {
    background-color: rgba(247, 174, 28, 0.8);
    color: #ffffff;
}
.item-offers-oldPrice {
    text-decoration: line-through;
    color: #7f7f7f;
    margin-left: 15px;
}