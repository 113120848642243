.promo-big-banner {
    position: relative;
    display: block;
}
.promo-big-banner .promo-big-content {
    height: 100%;
    overflow: hidden;
    position:absolute;
    top: 0;
    left: 28%;
    right: 18%;
}
.promo-big-banner .promo-big-content .promo-big-text {
    height: 100%;
    display: table;
}
.promo-big-banner .promo-big-content .promo-big-text p {
    display: table-cell;
    vertical-align: middle;
}