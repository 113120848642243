#product-finder-banner {
  padding: 15px;
  text-align: center;
  margin-bottom: 25px;
  background: url("../../images/web/productFinder/banner_mobile.webp") center;

  @media (min-width: 992px) {
    padding: 35px 50px 15px;
    text-align: left;
    background: url("../../images/web/productFinder/banner_desktop.webp") center;
  }

  * {
    color: #FFF;
  }

  .pfb-title {
    font-size: 25px;
    font-weight: bold;

    .tvi-product-finder {
      font-size: 50px;
      vertical-align: middle;
    }

    @media (min-width: 992px) {
      font-size: 45px;
      text-align: center;
      margin: 50px 0;

      .tvi-product-finder {
        font-size: 75px;
      }
    }
  }

  .pfb-subtitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0 35px;

    @media (min-width: 992px) {
      margin: initial;
      font-size: 25px;
    }
  }

  .pfb-btn {
    background-color: #FFF;
    color: #000 !important;
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
  }
}