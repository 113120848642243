.event-banner {
    background-color: #f7ae1c;
    cursor: pointer;
}
.results-event-banner {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.results-event-banner a {
    color: #000;
    font-weight: bold;
}
.event-banner-btn {
    padding: 6px 12px;
}
.product-page-event-banner {
    padding: 20px;
    cursor: pointer;
}
.product-page-event-banner a {
    color: #000;
    font-weight: bold;
}
.product-page-event-banner .event-description {
    color: #000;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}