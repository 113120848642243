
/* DEPRECATED */

.reviewStars{
    background-image: url(../../images/web/sprites/stars.png);
    display: block;
}
.reviewStars.starsContainer-overall{
    width: 154px;
    height: 26px;
    background-position: -2px -52px;
}
.reviewStars.starsContainer-review{
    width: 95px;
    height: 16px;
    background-position: -2px -82px;
}
.reviewStars.starsRating-overall{
    width: 154px;
    height: 26px;
    background-position: -2px -2px;
}
.reviewStars.starsRating-review{
    width: 95px;
    height: 16px;
    background-position: -2px -32px;
}
.infoRating-overall{
    margin-top: 10px;
    text-align: right;
}
.reviewItem{
    margin-bottom: 20px;
}
.reviewItem .reviewDate{
    color: #2196f3;
}
.reviewItem .reviewImage-link{
    text-decoration: none;
    vertical-align: super;
    margin: -10px 10px 0 0;
    display: inline-block;
}
.reviewItem .reviewImage-link .reviewImage-icon{
    color: #000000;
    font-size: 20px;
}
.reviewItem .reviewImage-link .reviewImage-text{
    text-decoration: underline;
    vertical-align: super;
    -webkit-transition: color .2s;
    -moz-transition: color .2s;
    -ms-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
}
.reviewItem .reviewImage-link:hover .reviewImage-text{
    color: #2196f3;
}

.spanRatingValue {
    font-weight: 700;
}

.productLink {
    font-size:12px;
}

.responseReviewp {
    font-style:italic;
}

.contentReviews {
    margin-bottom: 20px;
}

.containerReviewItem {
    margin-bottom: 15px;
    background-color: #ececec;
    border: 1px solid #d7d7d7;
    padding: 10px 15px;
}

.titleInfoReviewItem p {
    font-size:13px;
    margin: 0;
    display: inline;
    vertical-align: text-top;
}

.containerStarsValoration {
    text-align:right;
}

.containerStarsValoration .spanValoration {
    display:inline-block;
    margin-left: 10px;
}

.containerReviewStars {
    display:inline-block;
}

.glyphicon-comment {
    font-size:27px;
}

.containerResponseAdminReview {
    margin-top:20px;
    margin-bottom:10px;
}

.containerLinks {
    margin-top:15px;
}

.containerLinks span {
    color:#009587;
}

.contentImagesReview {
    margin-top:10px;
}

.view-image-container:not(:last-child) {
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    /*MOBILE XS*/
    .spanReviewDate {
        font-size:12px;
    }
}

@media (min-width: 768px) {
    /*TABLET SM*/
    .imgTenquality {
        float:right;
    }
    .spanReviewDate {
        float:left;
        font-size:13px;
    }

    .infoReviewsp {
        font-size:12px;
        margin-top: 10px;
    }

    .responseReviewp {
        margin-left:15px;
    }

    .reviewp {
        margin-left:0px;
    }
}
@media (min-width: 992px) {
    /*PC MD-LG*/
    .infoReviewsp {
        font-size:15px;
        margin-top: 10px;
    }

    .responseReviewp {
        margin-left:0px;
    }

    .reviewp {
        margin-left:-15px;
    }
}
.top-facet-reviews {
    margin: 10px 0;
}
.top-facet-review {
    padding: 5px 0;
}
.top-facet-review a {
    color: #2196f3;
    text-decoration: underline;
}
.top-facet-rating {
    text-align: right;
}

.review-text-row {
    margin-top: 10px;
}