.swiper-banner-home {
    position: relative;
}
.swiper-banner-home .swiper-button-prev, .swiper-banner-home .swiper-button-next {
    position: absolute;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-3 * var(--swiper-navigation-size) / 2);
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    --swiper-navigation-size: 30px;
}

.swiper-banner-home .swiper-button-disabled {
    display: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
}

.swiper-banner-home .swiper-pagination{
    bottom: 0;
    background: #fff;
    padding: 5px 1px;
    z-index: 1;
}