@import "product_finder_label";
@import "product_finder_banner";
@import "product-label-vars";
@import "../shared/responsive-vars";

body.overflow-hidden {
  overflow: hidden !important;
}

#product-finder {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -150%;
  width: 100%;
  z-index: 950;
  background: url("../../images/web/productFinder/bg_mobile.webp") center;
  background-size: cover;
  transition: right .5s;

  @media (min-width: 992px) {
    width: 64%;
    max-width: 800px;
    background: url("../../images/web/productFinder/bg_desktop.webp") center;
    background-size: cover;
  }

  &.pf-open {
    right: 0;
  }

  #pf-header {
    height: 75px;
    line-height: 75px;
    position: relative;
    padding: 0 15px;

    #pf-title {
      font-weight: bold;
      color: #FFF;
      font-size: 20px;
    }

    #pf-buttons {
      position: absolute;
      top: 0;
      right: 15px;

      * {
        color: #FFF;
        margin-left: 5px;
        cursor: pointer;
        font-size: 20px;
        vertical-align: middle;
      }
    }
    .pf-tooltip {
      position:relative;
      &:before {
        content: attr(data-mobile-text);
        @media (min-width: 992px) {
          content: attr(data-text);
        }
        position:absolute;
        bottom: -55px;
        transform: translateY(-50%);
        right: -43px;
        margin-left: 15px;
        width: 295px;
        padding: 9px 4px;
        border: 1px solid #F5F6F8;
        background: #F5F6F8;
        color: #000;
        text-align:center;
        font-size: 13px;
        line-height: 1;
        z-index: 100000;
        -webkit-box-shadow: 0 12px 12px -8px rgba(0,0,0,0.32);
        -moz-box-shadow: 0 12px 12px -8px rgba(0,0,0,0.32);
        box-shadow: 0 12px 12px -8px rgba(0,0,0,0.32);
        display: none;
      }
      &:after {
        position: absolute;
        content: '';
        width: 15px;
        height: 15px;
        border: 1px solid;
        border-color: #F5F6F8 transparent transparent #F5F6F8;
        background: #F5F6F8;
        top: 32px;
        transform:translateY(-50%) rotate(45deg);
        display:none;
      }
      &:hover:before, &:hover:after {
        display:block;
      }
      &.show-tip:before, &.show-tip:after {
        display:block;
      }
    }
  }

  #pf-body {
    position: absolute;
    top: 75px;
    bottom: 75px;
    width: 100%;
    text-align: center;
    overflow: hidden;

    &.bg-white {
      background-color: #FFF;
    }

    .pf-breadcrumb {
      color: #696969 !important;
      font-size: 14px;
      margin-top: 0.7rem!important;
      margin-left: 1rem!important;
      text-align: left;
      span:not(:last-child) {
        &:after {
          content: "\BB";
          padding: 0 10px;
        }
      }
    }

    #pf-start-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 15px;
      width: 100%;

      #pfs-title {
        color: #FFF;
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 25px;

        @media (min-width: 992px) {
          font-size: 50px;
        }
      }

      #pfs-text {
        color: #FFF;
        font-weight: bold;
        font-size: 18px;

        @media (min-width: 992px) {
          width: 50%;
          margin: 0 auto;
        }
      }

      #pfs-btn {
        background-color: #FFF;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        display: inline-block;
        padding: 10px 30px;
        margin-top: 25px;
        font-size: 18px;
      }
    }

    #pf-loading {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: #FFF;
      z-index: 1;
      opacity: .8;

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #000;
        font-size: 100px;
        display: block;
        width: 100%;

        &.pf-spin {
          -webkit-animation: pf-spin 2s infinite linear;
          animation: pf-spin 2s infinite linear;
          -webkit-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
        }
      }
    }
    #pf-working {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: #FFF;
      z-index: 10;
      opacity: 1;

      .working-message {
        position: absolute;
        top: 65%;
        transform: translateY(-50%);
        color: #000;
        font-size: 20px;
        text-align: center;
        width: 100%;
        margin-top: 3rem!important;
      }

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #000;
        font-size: 100px;
        display: block;
        width: 100%;
        text-align: center;

        &.pf-spin {
          -webkit-animation: pf-spin 2s infinite linear;
          animation: pf-spin 2s infinite linear;
          -webkit-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
        }
      }

    }

    .pf-answers, .pf-results {
      background-color: #FFF;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      transition: top .5s;
      overflow-y: auto;
      overflow-x: hidden;

      &.next {
        top: 200%;
      }
      &.prev {
        top: -200%;
      }

      .pfa-content, .pfr-content {
        background-color: #FFF;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 15px;
        width: 100%;
        height: 100%;

        @media (min-width: 992px) {
          top: 0;
          transform: none;
        }

        .pfa-txt, .pfr-txt {
          color: #000;
          font-size: 20px;
          margin: 30px 0;

          @media (min-width: 992px) {
            font-size: 30px;
            margin: 50px 15px;
          }
        }
        .pfa-txt, .pfr-txt {
          @media (min-width: 992px) {
            text-align: left;
          }
        }

        .pfa-answer {
          background-color: #e0e0e0;
          margin: 20px 15%;
          padding: 15px 20px;
          color: #000;
          font-weight: bold;
          cursor: pointer;
          transition: all .3s;
        }
      }

      &.current {
        .pfa-answer {
          &:hover {
            background-color: #000;
            color: #FFF;
          }
        }
      }

      .pfr-content {
        #pfp-arrows {
          position: absolute;
          top: 215px;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 85%;
          z-index: 1;

          @media (min-width: 768px) {
            width: 94%;
            top: 265px !important;
          }
          @media (min-width: 992px) {
            top: 320px !important;
            width: 95%;
          }

          span {
            position: absolute;
            font-size: 35px;
            color: #000;
            cursor: pointer;

            &#pfpa-prev {
              font-size: 25px;
              left: 0;
            }
            &#pfpa-next {
              font-size: 25px;
              right: 0;
            }
          }
        }

        .pfp-product {
          position: absolute;
          top: 350px !important;
          width: 65%;
          @media (min-width: 768px) {
            top: 285px !important;
            width: 85%;
          }
          @media (min-width: 992px) {
            top: 345px !important;
            width: 85%;
          }
          transform: translateY(-50%) translateX(-50%);
          transition: left .5s;

          a {
            text-decoration: none;
          }

          &.current {
            left: 50%;
          }
          &.next {
            left: 150%;
          }
          &.prev {
            left: -150%;
          }

          img {
            width: 100%;
          }

          .position-relative {
            position: relative;
          }
          .h-100 {
            height: 100%;
          }
          .w-100 {
            width: 100%;
          }
          .d-flex.flex-column  {
            display: flex;
            flex-direction: column;
            //flex-wrap: wrap;
            width: 100%;
          }

          .pfpp-title {
            width: 100%;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            float: left;
            color: #000;
            font-weight: bold;
            font-size: 15px;
            margin-bottom: 7px;
            /*margin-top: 10px;*/
          }
          .pfpp-desc {
            height: 80px;
            @media (min-width: 992px) {
              height: 80px;
            }
            line-height: 1.2;
            text-align: justify;
            font-size: 15px;
            @media (max-width: $break-md) {
              margin-top: 10px;
            }
          }
          .pfpp-price {
            width: 100%;
            color: #000;
            font-weight: bold;
            position: relative;
            bottom: 50px;
            @media (min-width: 992px) {
              right: 0;
              position: absolute;
              bottom: 47px;
            }
            .pfpp-title-mobile {
              float: left;
              text-align: left;
              width: 75%;
              overflow: hidden;
              font-weight: bold;
              color: #000;
            }
            .pfpp-price-value {
              width: 25%;
              @media (min-width: 992px) {
                width: 100%;
              }
              float: right;
              text-align: right;
              color: #000;
              font-weight: bold;
            }
          }
          .pfpp-btn {
            position: absolute;
            bottom: 0;
            color: #000;
            border: 1px solid;
            /*margin-top: 35px;*/
            padding: 10px;
            font-weight: bold;
            width: 100%;
            a {
              text-align: center;
              color: #000;
              font-weight: bold;
              text-decoration: none !important;
              width: 100%;
              font-size: 15px !important;
            }
            .glyphicon {
              color: #000;
              font-size: 16px;
              vertical-align: middle;
            }
          }

          .pf-advantages-block {
            height: 100px !important;
            @media (min-width: 992px) {
              height: 115px !important;
            }
            margin-top: 0.5rem !important;
            margin-bottom: 0.5rem !important;
            .splide__slide {
              text-align: left;
            }
            .innerTextAdvantage {
              text-align: left;
              font-weight: bold;
              font-size: 12px;
              color: #000;
            }
          }
          .top-label {
            margin-bottom: 5px;
            padding: 3px 10px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            &.new {
              background-color: $new-label-bg;
            }
            &.best-seller {
              background-color: $best-seller-label-bg;
            }
          }

          .item-customizable-label {
            cursor: pointer;
            width: 100%;
            z-index: 10;

            .item-customizable-item {
              float: left;
              margin-bottom: 5px;
              padding: 3px 10px;
              font-weight: bold;
              text-align: center;
              background-color: $personalizable-label-bg;
              color: #ffffff;
            }
          }

          .item-offer-label {
            width: 100%;
            z-index: 10;

            div {
              float: left;
              margin-bottom: 5px;
              padding: 3px 10px;
              font-weight: bold;
              text-align: center;
              color: #ffffff;

              &.item-free-shipping {
                background-color: $free-shipping-label-bg;
              }
              &.item-discount {
                background-color: $discount-label-bg;
              }
              &.item-black-discount {
                background-color: $black-discount-label-bg;
              }
            }
          }

          #productLabels {
            z-index: 10;
            position: absolute;
            bottom: 0;

            * {
              font-size: 11px;
            }
          }
          /*@media (max-width: 1199px) and (min-width: $break-md) {
            #productLabels {
              bottom: 28%;
            }
          }
          @media (max-width: $break-md) {
            #productLabels {
              bottom: 25%;
            }
          }*/

          #productLabelsTop {
            z-index: 10;
            position: absolute;
            top: 11px;

            * {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  #pf-footer {
    position: absolute;
    bottom: 0;
    height: 75px;
    width: 100%;
    padding: 0 15px;
    background-color: #FFF;

    .pf-pagelogo {
      position: absolute;
      bottom: 30px;
      right: 20px;
      height: 20px;
    }
  }
}

@keyframes pf-spin {
  from {
    transform:rotate(360deg);
  }
  to {
    transform:rotate(0deg);
  }
}
