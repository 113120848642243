#product-finder-label {
  right: -55px;
  line-height: 30px;
  width: 140px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  background-color: #000;
  position: fixed;
  top: 40%;
  z-index: 11;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  box-shadow: -2px -2px 4px 0 rgba(0, 0, 0, 0.4);
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;

  &.pf-label-hidden {
    transform: rotate(-90deg) translate(0, 40px);
    -webkit-transform: rotate(-90deg) translate(0, 40px);
    -moz-transform: rotate(-90deg) translate(0, 40px);
    -ms-transform: rotate(-90deg) translate(0, 40px);
    -o-transform: rotate(-90deg) translate(0, 40px);
  }

  .pfl-txt {
    font-size: 13px;
    font-weight: bold;
    color: #FFF;
  }

  .tvi-product-finder {
    color: #FFF;
    font-size: 25px;
    vertical-align: middle;
    display: inline-block;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
}